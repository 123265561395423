exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-classic-view-js": () => import("./../../../src/pages/classic-view.js" /* webpackChunkName: "component---src-pages-classic-view-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-document-index-js": () => import("./../../../src/pages/document-index.js" /* webpackChunkName: "component---src-pages-document-index-js" */),
  "component---src-pages-favorites-js": () => import("./../../../src/pages/favorites.js" /* webpackChunkName: "component---src-pages-favorites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-price-list-js": () => import("./../../../src/pages/price-list.js" /* webpackChunkName: "component---src-pages-price-list-js" */),
  "component---src-pages-products-list-js": () => import("./../../../src/pages/products-list.js" /* webpackChunkName: "component---src-pages-products-list-js" */),
  "component---src-pages-project-js": () => import("./../../../src/pages/project.js" /* webpackChunkName: "component---src-pages-project-js" */),
  "component---src-pages-qc-js": () => import("./../../../src/pages/qc.js" /* webpackChunkName: "component---src-pages-qc-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-price-list-js": () => import("./../../../src/templates/price-list.js" /* webpackChunkName: "component---src-templates-price-list-js" */),
  "component---src-templates-price-list-product-js": () => import("./../../../src/templates/price-list-product.js" /* webpackChunkName: "component---src-templates-price-list-product-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-qc-js": () => import("./../../../src/templates/qc.js" /* webpackChunkName: "component---src-templates-qc-js" */),
  "component---src-templates-view-js": () => import("./../../../src/templates/view.js" /* webpackChunkName: "component---src-templates-view-js" */)
}

